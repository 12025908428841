
  import { Component, Vue } from 'vue-property-decorator'
  import BaseEditor from '@/components/base/editor/BaseEditor.vue'
  import getApi from '@/api'
  import CourseEmailApi from '../../api/courseEmailApi'
  import { trim } from 'lodash'

  interface FromSendEmail {
    to: string[]
    subject: string
    content: string
  }

  const courseApi: CourseEmailApi = getApi('courseEmail')

  @Component({ components: { BaseEditor } })
  export default class SendEmailPage extends Vue {
    data: FromSendEmail = {
      to: [],
      subject: '',
      content: ''
    }

    isLoading = false

    created() {
      if (!this.checkRole(['all'], 'COURSE_DETAIL')) {
        this.$router.push('/')
      }
    }

    handleChange(text: string) {
      this.data.content = text
    }

    async handleSubmit() {
      if (this.isLoading) return
      if (trim(this.data.subject) === '' || trim(this.data.content) === '') {
        this.$notify.error('Subject, content is not empty')
        return
      }
      this.isLoading = true
      try {
        await courseApi.sendEmail(this.courseId, this.data)
        this.isLoading = false
        this.$message.success('Send email success')
      } catch (error) {
        this.isLoading = false
        this.$message.error('Send all email error')
      }
    }
  }
